var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", { attrs: { id: "pagelayout" } }, [
                _c("div", { attrs: { id: "datatable" } }, [
                  _c(
                    "table",
                    { staticClass: "table is-fullwidth" },
                    [
                      _vm._m(0),
                      _vm._l(_vm.sites, function (site) {
                        return _c("tr", { key: site.id }, [
                          _c("td", [
                            _c("div", { staticClass: "field" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "EditSite",
                                          params: { id: site.id, model: site },
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(site.name))]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("td", [_vm._v(_vm._s(site.displayName))]),
                          _c("td", [_vm._v(_vm._s(site.url))]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Site Name")]),
      _c("th", [_vm._v("Company Name")]),
      _c("th", [_vm._v("Site URL")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }